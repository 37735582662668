.metadata
{
  border-radius: 5px;
  padding: 4px;
  font-size: 0.8rem;
  background-color: #d9d9d9;
}

.metadata-container
{
  margin: 14px 0;
}

.links
{
  margin: 14px 0;
  display: flex;
  align-items: center;
  .link
  {
    display: inline;
    margin: 0 16px 0 0;
    a
    {
      display: flex;
      align-items: center;
    }
    .home
    {
      color: #ffffff;
      background-color: #b55f05;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 0.8rem;
    }
  }
}

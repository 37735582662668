.about-us
{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  background-color: #FFC735;
  overflow: scroll;
  /* Edge 対応 */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;

  &::-webkit-scrollbar
  {
    display: none;
  }

  .container
  {
    margin: 0;
    padding: 0;
    padding-bottom: 50px;
    a
    {
      word-break: break-all;
      font-size: 15px;
    }
  }

  .branding
  {
    margin-bottom: 40px;
    .logo
    {
      font-size: 28px;
    }
  }

  p
  {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  h2
  {
    font-size: 1.4rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .media {
    margin: 10px 0;
  }

  .form-container {
    width: 100%;
    text-align: center;
    margin: 50px 0;
    .form
    {
      color: #fff;
      background-color: #060000;
      font-size: 18px;
      padding: 20px 40px;
      border-radius: 40px;
    }
  }

  .mb3rem {
    margin-bottom: 3rem
  }
}
